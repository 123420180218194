import React, { useState } from "react";
import { ArrowCircleRight } from "@phosphor-icons/react";

const BlogArtical = ({ data = {} }) => {
  const [expanded, setExpanded] = useState(false);

  const displayText = expanded ? data?.text : data.text?.slice(0, 200);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="">
      <div className="relative">
        <img src={data.image} alt="" className="" />
        <span className="absolute  lg:left-[5%] bottom-0  text-white">
          <p className="bg-blueFont p-1.5 text-sm">{data.category}</p>
        </span>
      </div>
      <div className="lg:w-[90%] mx-auto mt-small">
        <p className="text-lightGreen font-semibold">{data.date}</p>
        <h2 className="text-2xl sm:text-3xl font-semibold text-blueFont mb-3">
          {data.title}
        </h2>
        <p
          className={`transition-text ease-in duration-100 ${
            !expanded ? "text-sm" : "text-base"
          }`}
        >
          {displayText}
          {data.text.length > 200 && !expanded && (
            <span className="text-sm"> ...</span>
          )}
          {!expanded && data.text.length > 200 && (
            <button
              onClick={toggleExpansion}
              className="flex items-cenetr mt-small gap-x-2"
            >
              <ArrowCircleRight size={26} weight="fill" color="#7AC143" />
              Read more
            </button>
          )}
        </p>
        {expanded && data.text.length > 200 && (
          <button
            onClick={toggleExpansion}
            className="flex items-cenetr mt-small gap-x-2"
          >
            <ArrowCircleRight size={26} weight="fill" color="#7AC143" />
            Show less
          </button>
        )}
      </div>
    </div>
  );
};

export default BlogArtical;
