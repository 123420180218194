import {
  Phone,
  EnvelopeSimple,
  MicrosoftOutlookLogo,
} from "@phosphor-icons/react";

const mapContent = [
  {
    title: "MDS Computers Technical System LLC",
    text: [
      "Damac Business Tower,",
      "12th Floor, Unit 1206-1208, Business Bay, PO Box 62762, Dubai.",
    ],
    list: [
      {
        link: "+971 04 429 3555",
        linkText: "+971 04 429 3555",
        icon: <Phone weight="fill" />,
      },
    ],

    location: [25.276987, 55.296249],
  },

  {
    title: "Mideast Data Systems LLC",
    text: [
      "Addax Business Towers, ",
      "32nd Floor, Reem Island, PO Box 7899 Abu Dhabi, Untied Arab Emirates.",
    ],
    list: [
      {
        link: "+971 2 6130700",
        linkText: "+971 2 6130700",
        icon: <Phone weight="fill" />,
      },
      {
        link: "+971 2 6271114",
        linkText: "+971 2 6271114",
        icon: <MicrosoftOutlookLogo weight="fill" />,
      },
      {
        link: "info@mdscomputers.ae",
        linkText: "info@mdscomputers.ae",
        icon: <EnvelopeSimple weight="fill" />,
      },
    ],

    location: [24.453884, 54.377343],
  },
];

export default mapContent;
