import logo from "assests/image/logi.png";
import { Link } from "react-router-dom";

const Logo = ({ handleMegaMenu }) => {
  return (
    <Link to={"/"} onMouseEnter={() => handleMegaMenu(null)}>
      <div className="w-36 relative z-[100] ">
        <img src={logo} alt="" />
      </div>
    </Link>
  );
};

export default Logo;
