import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Autoplay, Keyboard, Navigation } from "swiper/modules";

import { CaretRight, CaretLeft } from "@phosphor-icons/react";
import ItemListDisplay from "components/UI/ItemListDisplay";

import "./Slider.css";

const CapabiltiesSlider = ({ data }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);

  const handleMouseEnter = () => {
    if (swiper) {
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiper) {
      swiper.autoplay.start();
    }
  };

  return (
    <div className="solxution-container relative">
      <button
        ref={prevRef}
        className="absolute top-1/2 -translate-y-1/2 left-6 z-[100] solution-prev-button hidden md:block"
      >
        <CaretLeft />
      </button>
      <button
        ref={nextRef}
        className="absolute top-1/2 -translate-y-1/2 right-6 z-[100] solution-next-button hidden md:block"
      >
        <CaretRight />
      </button>
      <div className="big-container">
        <Swiper
          onSwiper={(s) => setSwiper(s)}
          slidesPerView={1.1}
          spaceBetween={8}
          speed={1000}
          loop={true}
          modules={[Navigation, Autoplay, Keyboard]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          keyboard={{
            enabled: true,
          }}
          navigation={{
            clickable: true,
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
          breakpoints={{
            578: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 14,
            },
            992: {
              slidesPerView: 4,
              slidesPerGroup: 1,
            },
          }}
        >
          {data?.map((sol, index) => (
            <SwiperSlide
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              key={index}
            >
              <ItemListDisplay isSlider={true} data={sol} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CapabiltiesSlider;
