// components
import Hero from "./Hero/Hero";
import Landing from "./Landing/Landing";
import Features from "./Features/Features";
import Values from "./Values/Values";
import AboutSolutionSlider from "./SolutionSlider/SolutionSlider";
import OfficesSection from "./Offices/OfficesSection";

// fetching
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
import { useState, useEffect } from "react";

const About = () => {
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState();

  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData("about-us");
      setData(response);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  if (loading) return <IsLoading />;
  else if (isError) return <IsError />;
  else if (data) {
    const aboutData = data;
    return (
      <main>
        <Hero data={aboutData?.hero} />
        <Landing data={aboutData} />
        <Values data={aboutData.values} />
        <Features data={aboutData?.WhatMakesUsDifferent} />
        <AboutSolutionSlider />
        <OfficesSection data={aboutData?.offices} />
      </main>
    );
  }
};

export default About;
