import { Link } from "react-router-dom";
import sectionLinks from "constant/Footer/SectionsLink";

// solutiom content

const SolutionFooter = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav>
      <ul className="flex flex-col gap-y-2">
        {sectionLinks.map((link, indx) => (
          <li
            key={indx}
            className="text-blueFont font-semibold capitalize transition-underline ease-in duration-300 hover:underline"
          >
            <Link to={link.link} onClick={scrollToTop}>
              {link.section}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SolutionFooter;
