import { Routes, Route, Link, useLocation } from "react-router-dom";

// context
import ContextProvider from "context/ContextProvider";
// UI
import ScrollToTop from "utility/ScrollToTop";
// layout
import Header from "layout/Header/Header";
import Footer from "layout/Footer/Footer";
// components
import Home from "Pages/Home/Home";
import Capabilities from "Pages/Capabilities/Capabilities";
import About from "Pages/About/About";
import Partners from "Pages/Partners/Partners";
import Solution from "Pages/Solution/Solution";
import Customer from "Pages/Customer&Success/Customer/Customer";
import Success from "Pages/Customer&Success/Success/Success";
import Blog from "Pages/Media/Blog/Blog";
import News from "Pages/Media/News/News";
import DisplayNews from "Pages/Media/News/DisplayNews/DisplayNews";
import ContactUs from "Pages/contact/ContactUs";
import JoinUs from "Pages/JoinUs/JoinUs";
import NotFound from "Pages/NotFound";
import DetailedDisplayScreen from "Pages/DetailedDisplayScreen/DetailedDisplayScreen";
import DetailedDisplayScreenSolution from "Pages/DetailedDisplayScreen/DetailedDisplayScreenSolution";
const App = () => {
  const location = useLocation();

  // Check if the current route is Home
  const isHomePage = location.pathname === "/";
  return (
    <div className="App">
      <ContextProvider>
        <ScrollToTop />
        <Header />

        <Routes>
          <Route index element={<Home />} />
          <Route path="who-we-are" element={<About />} />
          <Route path="capabilities" element={<Capabilities />} />
          <Route path="partners" element={<Partners />} />
          <Route path="solutions" element={<Solution />} />
          <Route path="customer" element={<Customer />} />
          <Route path="success" element={<Success />} />
          <Route path="join-us" element={<JoinUs />} />
          <Route path="blog" element={<Blog />} />
          <Route path="news" element={<News />} />
          <Route path="news/:slug" element={<DisplayNews />} />
          <Route path="reach-out" element={<ContactUs />} />
          <Route
            path="display/capabilties/:slug"
            element={<DetailedDisplayScreen />}
          />
          <Route
            path="display/solution/:slug"
            element={<DetailedDisplayScreenSolution />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />

        <Link
          to={"/reach-out"}
          className={`fixed -right-[2.8rem] py-2 rounded-tl-lg rounded-tr-lg text-white px-6 top-1/2 -translate-y-1/2 bg-lightGreen z-[100000] rotate-[-90deg] 
            ${isHomePage ? "hidden lg:block" : "block"}`}
        >
          Reach out
        </Link>
      </ContextProvider>
    </div>
  );
};

export default App;
