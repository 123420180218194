import Container from "components/Container/Container";
// content
import image from "../../../../assests/image/About/about-landing/bgab.jpg";
import "../LandingStyle.css";

const FirstSection = ({ data = {} }) => {
  return (
    <Container className="pt-secondary lg:py-20">
      <div>
        <div className="lg:w-[75%] relative z-[1]">
          <h2 className="text-3xl font-audio mb-3 text-blueFont ">
            {data?.title}
          </h2>

          <div
            className="custom-scroll font-philo text-font font-[500] max-h-[350px] overflow-y-scroll pr-10"
            dangerouslySetInnerHTML={{ __html: data?.text }}
          />
        </div>
        <div className="absolute bottom-0 left-0 w-full h-full z-[0]">
          <img src={image} alt="" className="w-full h-full object-cover" />
        </div>
      </div>
    </Container>
  );
};

export default FirstSection;
