import React, { createContext, useState, useEffect } from "react";
import GetContent from "utility/GetContent";

export const NewsContext = createContext();

export const NewsProvider = ({ children }) => {
  const [fullNewsContent, setFullNewsContent] = useState(null);
  // this state represent the news artical that will be render after we remove the latest 3 news artical
  const [newsContent, setNewsContent] = useState(null);
  // this state will represent the latest 3 news atical to render them in the banner
  const [latestNews, setLatestNews] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isRequestError, setIsRequestError] = useState(false);

  const getNewsData = async () => {
    let isMounted = true;

    setIsRequestError(false);
    try {
      setIsLoading(true);

      const newsData = await GetContent("latest-news");
      setFullNewsContent(newsData?.latestNews);

      if (newsData?.latestNews) {
        const sortedNews = newsData?.latestNews.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        const latestThreeNews = sortedNews.slice(0, 3);

        // Filter out the latestThreeNews from newsData
        const remainingNews = newsData.latestNews.filter(
          (news) => !latestThreeNews.some((latest) => latest.date === news.date)
        );

        // Update setNewsContent with the remaining news
        setNewsContent({ ...newsData, latestNews: remainingNews });

        // Set the latestThreeNews state
        setLatestNews(latestThreeNews);
      }
    } catch (error) {
      setIsRequestError(true);
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getNewsData();
  }, []);

  return (
    <NewsContext.Provider
      value={{
        fullNewsContent,
        newsContent,
        latestNews,
        isLoading,
        isRequestError,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};
