import SectionHeader from "components/UI/SectionHeader";
import SolutionSlider from "Pages/Home/Solution/SolutionSLider";
import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
import { useState, useEffect } from "react";

const AboutSolutionSlider = () => {
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData("solution");
      setData(response?.solutions);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <section className="my-secondary lg:my-primary ">
      <div className="big-container">
        <SectionHeader title={" SOLUTIONS"} titleColor={"blueFont"} />
      </div>

      <SolutionSlider data={data} />
    </section>
  );
};

export default AboutSolutionSlider;
