import CapabiltiesSlider from "./Slider/CapabiltiesSlider";
const Capabilties = ({ data }) => {
  return (
    <section className="py-primary bg-blueFont">
      <div className="big-container mb-small md:mb-secondary ">
        <h4 className="lg:text-center text-white text-4xl lg:text-5xl font-bold">
          Capabilities
        </h4>
      </div>
      <CapabiltiesSlider data={data} />
    </section>
  );
};

export default Capabilties;
