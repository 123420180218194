import { useState } from "react";
// UI
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";

import "./aboutHeroStyle.css";

const Hero = ({ data = {}, isLoading, isError }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const loadingImage = () => {
    setIsImageLoading(false);
  };

  return (
    <section
      className={`w-full hero-section overflow-hidden ${
        isImageLoading ? "bg-blueFont" : ""
      }`}
    >
      <div className="relative content-parent">
        {/* when the data is loading or return error rendering default image */}
        <div className={`image-parent relative`}>
          {!isLoading && !isError && (
            <img
              src={data?.image}
              className="hero-image object-cover"
              alt=""
              onLoad={loadingImage}
            />
          )}
        </div>

        {isImageLoading && (
          <div className="flex flex-col items-center gap-y-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center bg-blueFont"></div>
        )}

        {/* render a spinenr white the data is fetcing */}
        {isLoading && (
          <div className="flex flex-col items-center gap-y-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
            <IsLoading isWhite={true} />
          </div>
        )}
        {/* render error message when the API reuqets faield */}
        {isError && (
          <div className="flex flex-col items-center gap-y-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
            <IsError isWhite={true} />
          </div>
        )}
        {/* render the content after loading is done, and APU request is success */}
        {!isLoading && !isError && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hero-conent text-center">
            <h1
              className={`text-white text-3xl lg:text-[2.8rem] xll:text-[3.2rem] leading-[1.1]  font-audio mb-2 uppercase lg:w-1/2 mx-auto`}
            >
              {data?.title}
            </h1>

            <p className="text-white text-lg ">{data?.subTitle}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Hero;
