import Container from "components/Container/Container";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";

// slider
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "./Gallery.css";
import "swiper/css/pagination";

const Gallery = ({ data = [], isError, isLoading, isGallery }) => {
  return (
    <section className={`mt-primary bg-[#F2F2F2] py-10 `}>
      <Container>
        <h1 className="text-blueFont text-3xl font-audio mb-small">
          Project Gallery If needed
        </h1>

        <Swiper
          grabCursor={true}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
          breakpoints={{
            578: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination]}
          className="successGallerySlider mt-secondary"
          loop={true}
          speed={800}
        >
          {data?.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt="" className="h-52 w-full object-contain" />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default Gallery;
