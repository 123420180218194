import Container from "components/Container/Container";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
// components
import LatestNewsSlider from "../LatestNewsSlider/LatestNewsSlider";

import "./NewsHero.css";

const Hero = ({ data, isLoading, isError }) => {
  return (
    <section className="news-hero bg-news-hero">
      <Container>
        <div className="flex flex-col justify-center news-content-parent">
          <div className="mt-24 lg:mt-10">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-audio text-lightGreen">
              Media
            </h1>
            <h4 className="text-white font-audio text-2xl sm:text-3xl mt-2">
              Our Latest Articles and News
            </h4>
          </div>

          <div className="pt-6 pb-24 md:pb-10 lg:pb-0">
            {isLoading && <IsLoading isWhite={true} />}
            {isError && <IsError isWhite={true} />}
            {!isLoading && <LatestNewsSlider data={data} />}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
