const sectionLinks = [
  {
    section: "Solutions",
    link: "/solutions",
  },
  {
    section: "Capabilities",
    link: "/capabilities",
  },
  {
    section: "Who we are",
    link: "/who-we-are",
  },
  {
    section: "Join us",
    link: "/join-us",
  },
  // {
  //   section: "Reach out",
  //   link: "/reach-out",
  // },
  {
    section: "Partners",
    link: "/partners",
  },
  // {
  //   section: "News",
  //   link: "/news",
  // },
  // {
  //   section: "Blog",
  //   link: "/blog",
  // },
];

export default sectionLinks;
