import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// components
import Container from "components/Container/Container";
import Spinner from "components/UI/Spinner";
import SmallHero from "components/UI/Heros Section/SmallHero";
// utilities
import GetContent from "utility/GetContent";
import { CaretLeft } from "@phosphor-icons/react";

const DisplayNews = () => {
  const { slug } = useParams();
  const [newsContent, setNewsContent] = useState(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isRequestError, setIsRequestError] = useState(false);
  useEffect(() => {
    const getNewArtical = async () => {
      setIsLoading(true);
      try {
        const newsArtical = await GetContent("latest-news", slug);

        setNewsContent(newsArtical.selectedNew);
      } catch (error) {
        setIsRequestError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getNewArtical();
  }, []);

  return (
    <main>
      <SmallHero title={newsContent?.title} />

      <section className="mt-bigSmall mb-primary">
        <Container>
          {isLoading && (
            <div className="flex flex-col items-center">
              <Spinner />
              <p>Loading data..</p>
            </div>
          )}
          {!isLoading && (
            <>
              <div className="my-6 flex items-center gap-x-0">
                <CaretLeft size={20} />
                <button onClick={() => navigate(-1)}>Go Back</button>
              </div>
              <div className="w-full max-h-[500px]">
                <img
                  src={newsContent?.image}
                  className="w-full object-cover max-h-[500px]"
                  alt=""
                />
              </div>

              <div className="mt-small lg:w-[80%] mx-auto">
                <h2 className="text-2xl sm:text-3xl font-medium text-blueFont mb-2">
                  {newsContent?.title}
                </h2>

                <p>{newsContent?.text}</p>
              </div>
            </>
          )}
        </Container>
      </section>
    </main>
  );
};

export default DisplayNews;
