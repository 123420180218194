import Container from "components/Container/Container";
import SectionHeader from "components/UI/SectionHeader";
import PartnersSlider from "./PartnersSlider";

const Partners = ({ data }) => {
  return (
    <section className="py-secondary lg:py-primary">
      <Container>
        <SectionHeader
          title={"our technology partners"}
          titleColor={"blueFont"}
        />
        <PartnersSlider data={data} />
      </Container>
    </section>
  );
};

export default Partners;
