import Container from "components/Container/Container";

const PartnerList = ({ data }) => {
  return (
    <section className="my-secondary lg:my-primary">
      <Container>
        <div className="flex flex-col gap-y-12">
          {data?.map((cont, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row gap-4 md:gap-10"
            >
              <div className=" max-w-[8.2rem] h-[8.2rem]">
                <img
                  src={cont.image}
                  alt=""
                  className="min-w-[8.2rem] h-[8.2rem] object-contain"
                />
              </div>
              <div className="border-t pt-4 md:border-t-0 md:pt-0 md:border-l border-black md:pl-10 ">
                <h2 className="text-lightGreen text-2xl font-semibold mb-2">
                  {cont.title}
                </h2>
                <p>{cont.text}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default PartnerList;
