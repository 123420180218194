import Container from "components/Container/Container";
import IsError from "components/UI/Request Handling/IsError";
import IsLoading from "components/UI/Request Handling/IsLoading";
// slider
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "./Success.css";
import "swiper/css/pagination";

const SuccessSlider = ({
  data = [],
  isError,
  isLoading,
  getStoryHandler,
  isGallery,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section>
      <Container
        className={`mt-secondary pt-secondary ${isGallery ? "" : "border-t-2"}`}
      >
        <div className=" text-blueFont text-3xl font-audio mb-secondary">
          <h1>Other Success Stories</h1>
        </div>

        {isLoading && <IsLoading />}
        {isError && <IsError />}
        {!isLoading && !isError && (
          <Swiper
            grabCursor={true}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            slidesPerView={2}
            breakpoints={{
              578: {
                slidesPerView: 3,
              },

              992: {
                slidesPerView: 5,
              },
            }}
            modules={[Pagination]}
            className="otherSucessSlider  "
            loop={true}
            slidesPerGroup={5}
            speed={800}
          >
            {data?.map((storie, index) => (
              <SwiperSlide key={index}>
                <button
                  className="w-36 h-28 flex items-center justify-center"
                  onClick={() => {
                    getStoryHandler(storie.slug);
                    scrollToTop();
                  }}
                >
                  <img
                    src={storie.image}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </button>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Container>
    </section>
  );
};

export default SuccessSlider;
