import React from "react";
import DesktopNav from "./Desktop/DesktopNav";
import MobileNav from "./Mobile/MobileNav";

const Navigation = ({ isClicked, handleMegaMenu }) => {
  return (
    <>
      <DesktopNav isClicked={isClicked} handleMegaMenu={handleMegaMenu} />
      <MobileNav />
    </>
  );
};

export default Navigation;
