import React, { useState } from "react";
import { Link, useLocation, NavLink } from "react-router-dom";
import GetLinks from "constant/HeaderLinks";
import { CaretDown, CaretRight, List } from "@phosphor-icons/react";
import Container from "components/Container/Container";
const DesktopNav = ({ isClicked, handleMegaMenu }) => {
  const { pathname } = useLocation();
  const navLinks = GetLinks();

  const [navIsActive, setNavIsActive] = useState(false);
  const navIsActiveHandler = () => {
    setNavIsActive(true);
  };
  const navCloseActiveHandler = () => {
    setNavIsActive(false);
  };

  return (
    <nav className="hidden xl:block ">
      <ul className="flex  ">
        {navLinks?.map(({ text, megaNav, url, links }, indx) =>
          megaNav ? (
            <button
              onMouseEnter={navIsActiveHandler}
              onMouseLeave={navCloseActiveHandler}
              key={indx}
              className={`text-white font-mono uppercase font-medium flex gap-x-1 group py-2 px-6 `}
            >
              <p className="font-mono">{text}</p>
              <span
                className={`transition ease-in duration-300 ${
                  isClicked === indx ? "rotate-[180deg]" : ""
                }`}
              >
                <CaretDown size={20} />
              </span>

              <div
                className={`absolute left-0 top-[100%]  transition-all ease-in-out duration-300 text-white bg-[#195677]  z-[100000] opacity-0 invisible translate-y-[10%]  group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 py-16 w-full  `}
              >
                <Container className="grid grid-cols-5 gap-10">
                  {links?.map(({ title, path, data, sol }, index) => (
                    <div
                      key={index}
                      className={`flex flex-col ${
                        data.length > 5 ? "col-span-3" : "col-span-2"
                      }`}
                    >
                      <Link
                        key={index}
                        to={path}
                        className="transition ease-in duration-300 text-start font-bold font-mono text-white hover:text-[#bbb] text-2xl  "
                      >
                        {title}
                      </Link>

                      <ul
                        className={` text-start text-sm mt-6 ${
                          data.length > 5
                            ? "grid grid-cols-2 justify-between w-full"
                            : "flex flex-col"
                        }  gap-y-3 gap-x-10`}
                      >
                        {data?.map((o) => (
                          <li>
                            <Link
                              to={`/display/${
                                sol ? "solution" : "capabilties"
                              }/${o.slug}`}
                              className="hover:underline"
                            >
                              {o.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </Container>
              </div>
            </button>
          ) : (
            <NavLink
              exact
              onMouseEnter={() => handleMegaMenu(null)}
              to={url}
              key={indx}
              className={`uppercase  font-medium py-2 px-6 ${
                url === pathname ? "text-lightGreen  " : "text-white"
              }`}
            >
              {text}
            </NavLink>
          )
        )}
      </ul>

      <div
        onMouseEnter={navCloseActiveHandler}
        className={` ${
          navIsActive
            ? "opacity-100 visible scale-1"
            : "opacity-0 invisible scale-1/2"
        } fixed w-[100vw] h-[100vh] top-0 select-none left-0 bg-[#000000b0] z-[-1] transition-all ease-in duration-300`}
      ></div>
    </nav>
  );
};

export default DesktopNav;
// import React, { useState } from "react";
// import { Link, useLocation, NavLink } from "react-router-dom";
// import GetLinks from "constant/HeaderLinks";
// import Container from "components/Container/Container";
// import { CaretDown } from "@phosphor-icons/react";

// const DesktopNav = ({ isClicked, handleMegaMenu }) => {
//   const { pathname } = useLocation();
//   const navLinks = GetLinks();

//   return (
//     <nav className="hidden xl:block ">
//       <ul className="flex  gap-x-10 ">
//         {navLinks?.map(({ text, megaNav, url, links, pages }, indx) =>
//           megaNav ? (
//             <React.Fragment key={indx}>
//               <button
//                 // onMouseEnter={() => handleMegaMenu(indx)}
//                 key={indx}
//                 className={`text-white  font-mono  uppercase font-medium group/edit flex gap-x-1 ${
//                   isClicked === indx ? "border-b border-white" : ""
//                 }`}
//               >
//                 <p className="font-mono ">{text}</p>
//                 <span
//                   className={`transition ease-in duration-300 ${
//                     isClicked === indx ? "rotate-[180deg]" : ""
//                   }`}
//                 >
//                   <CaretDown size={20} />
//                 </span>

//                 <div
//                   className={`absolute left-0 top-[100%] min-w-[14rem] w-full  h-auto  transition ease-in duration-300 text-white   bg-[#195677] py-secondary z-[100000]  ${
//                     isClicked === indx
//                       ? "opacity-100 visible translate-y-[0%] "
//                       : "opacity-0 invisible translate-y-[20%] "
//                   }`}
//                 >
//                   <Container>
//                     <div className="flex gap-x-28 xll:gap-x-40    font-mono">
//                       {links?.map(({ title, links }, index) => (
//                         <div key={index}>
//                           <h4 className="text-xl font-bold mb-6 font-mono text-[#bbb]">
//                             {title}
//                           </h4>
//                           <ul
//                             className={`flex ${
//                               links?.length > 5
//                                 ? "grid grid-cols-2 gap-x-10 xxl:gap-x-24 justify-between w-full"
//                                 : "flex-col "
//                             } gap-y-4`}
//                           >
//                             {links?.map(({ name, link }, index) => {
//                               const isActive =
//                                 pathname === link && link !== "/capabilities"; // Check if link is active and not "capabilities"
//                               return (
//                                 <Link
//                                   to={link}
//                                   state={name}
//                                   onClick={() => handleMegaMenu(null)}
//                                   className={`font-mono hover:underline text-sm font-semibold ${
//                                     isActive ? "text-lightGreen" : "text-white"
//                                   }`}
//                                   key={index}
//                                 >
//                                   {name}
//                                 </Link>
//                               );
//                             })}
//                           </ul>
//                         </div>
//                       ))}
//                     </div>
//                   </Container>
//                 </div>
//               </button>
//             </React.Fragment>
//           ) : (
//             <NavLink // Use NavLink instead of Link
//               exact // Use exact to ensure only exact matches get the active class
//               onMouseEnter={() => handleMegaMenu(null)}
//               to={url}
//               key={indx}
//               className={`uppercase  font-medium ${
//                 url === pathname ? "text-lightGreen  " : "text-white"
//               }`}
//             >
//               {text}
//             </NavLink>
//           )
//         )}
//       </ul>

//       {isClicked !== null && (
//         <div
//           onClick={() => handleMegaMenu(null)}
//           className="fixed w-[100vw] h-[100vh] top-0 left-0 bg-[#00000029] z-[-1]"
//         ></div>
//       )}
//     </nav>
//   );
// };

// export default DesktopNav;
