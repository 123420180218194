import React from "react";
import Container from "components/Container/Container";
import locationHandler from "utility/locationHandlder";
import { Phone, EnvelopeSimple, MapPin } from "@phosphor-icons/react";
const OfficesSection = ({ data }) => {
  const locationMaps = [
    "https://maps.app.goo.gl/Sd3z8mfoN8a7Lftp6?g_st=iw",
    "https://maps.app.goo.gl/yp9PRY8ABDaid9hs6?g_st=iw",
  ];
  return (
    <section className="bg-blueFont mt-[12rem] py-20">
      <Container>
        <p className="text-4xl text-lightGreen font-audio ">Our Offices</p>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-y-24 gap-x-32 mt-8">
          {data?.map(
            (
              { city, location, street, phone_number, email, location_map },
              index
            ) => (
              <div className="flex-1  font-mono  text-white" key={index}>
                <h6 className="text-xl font-bold ">{city}</h6>
                <p>{location}</p>

                <p className="my-4 font-light">{street}</p>

                <a
                  href={`tel:${phone_number}`}
                  className="flex items-center gap-x-2 mb-3 hover:underline"
                >
                  <span className="bg-lightGreen p-1 rounded-full ">
                    <Phone weight="fill" color="#03466A" size={16} />
                  </span>
                  {phone_number}
                </a>
                <a
                  href={`mailto:${email}`}
                  className="flex items-center lowercase gap-x-2 mb-3 hover:underline"
                >
                  <span className="bg-lightGreen p-1 rounded-full ">
                    <EnvelopeSimple weight="fill" color="#03466A" size={16} />
                  </span>
                  {email}
                </a>

                <button
                  className="flex items-center gap-x-2 hover:underline"
                  onClick={() => locationHandler(locationMaps[index])}
                >
                  <span>
                    <MapPin size={28} />
                  </span>
                  View Location Map
                </button>
              </div>
            )
          )}
        </div>
      </Container>
    </section>
  );
};

export default OfficesSection;
