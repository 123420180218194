import Container from "components/Container/Container";

// slider
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "./Slider.css";
import "swiper/css/pagination";

const PartnersSlider = ({ data }) => {
  return (
    <section className="border-t-4 border-[#D1D3D4] lg:my-primary">
      <Container>
        <h2 className="pt-4 text-[#D1D3D4] text-3xl font-medium ">
          Other Partners
        </h2>

        <Swiper
          grabCursor={true}
          speed={800}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          slidesPerView={2}
          slidesPerGroup={2}
          breakpoints={{
            578: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },

            992: {
              slidesPerView: 6,
              slidesPerGroup: 6,
            },
          }}
          modules={[Pagination]}
          className="partnerAboutSwipper mt-6 mb-14"
          loop={true}
        >
          {data?.map((slide, index) => (
            <SwiperSlide
              key={index}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="w-36 h-24 md:w-28 md:h-28 flex items-center justify-center">
                <img
                  src={slide}
                  alt=""
                  className="h-full w-full image-slider"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default PartnersSlider;
