import { Link } from "react-router-dom";
// UI
import Container from "components/Container/Container";
// content
import aboutContent from "constant/Home/About";
// icons
import { ArrowCircleRight } from "@phosphor-icons/react";

const About = ({ data = {} }) => {
  return (
    <section
      style={{ backgroundImage: `url(${aboutContent.image})` }}
      className="mt-secondary lg:mt-0 py-secondary lg:py-primary partners-bg"
    >
      <Container className="">
        <div className="">
          <div className="md:w-3/4 lg:w-[45%] xll:w-[40%] mb-secondary ">
            <h5 className={`capitalize font-medium mb-2 text-blueFont`}>
              {data?.title}
            </h5>
            <h2
              className={`text-lightGreen font-audio text-2xl md:text-3xl font-medium first-letter:uppercase  mb-small`}
            >
              {data?.subTitle}
            </h2>

            <div dangerouslySetInnerHTML={{ __html: data?.text }} />
          </div>
          <span className="flex items-center gap-x-2 mt-bigSmall">
            <ArrowCircleRight size={26} weight="fill" color="#7AC143" />

            <Link to={"/who-we-are"}>Read more</Link>
          </span>
        </div>
      </Container>
    </section>
  );
};

export default About;
