import contactUs from "constant/Footer/ContactUs";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import performGetFormStatus from "utility/GetContent";
const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const contactData = await performGetFormStatus("contact-info");
        setContactInfo(contactData?.contact);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="col-span-1 md:col-span-2 lg:col-span-1">
      <Link
        to={"/reach-out"}
        className="text-blueFont font-semibold capitalize transition-underline ease-in duration-300 hover:underline mb-3"
      >
        Reach out
      </Link>
      <div className=" grid  grid-cols-1 md:col-span-2 lg:col-span-1 justify-between md:grid-cols-2 gap-4 lg:gap-14">
        {contactInfo?.map(
          (
            { city, location, street, phone_number, email, location_map },
            index // Add parentheses for the inner map
          ) => (
            <span
              key={index}
              className="flex flex-col gap-y-2 text-gray-500 text-sm mb-2 font-[500]"
            >
              <p>{city}</p>
              <p>{street}</p>
              <a
                href={`tel:${phone_number}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                Tel: {phone_number}
              </a>

              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer "
                className="hover:underline "
              >
                Email: <span className="lowercase">{email}</span>
              </a>
            </span>
          )
        )}
      </div>
    </div>
  );
};

export default ContactUs;
