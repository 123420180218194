import Container from "components/Container/Container";
// content
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../LandingStyle.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

const SliderSection = ({ data = [] }) => {
  const content = data;
  return (
    <Container className="pt-secondary pb-[8rem] lg:pb-[14rem]">
      <Swiper
        spaceBetween={40}
        centeredSlides={true}
        speed={1000}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="heroAboutSlider border-t-2 border-lightGreen"
      >
        {content?.map((slide, index) => (
          <SwiperSlide key={index} className="py-primary">
            <div className="flex flex-col-reverse lg:flex-row items-center md:items-start lg:items-center ">
              <div className="flex-1">
                <h2 className="text-lightGreen text-3xl font-audio mb-3  ">
                  {slide?.title}
                </h2>
                <div
                  className="about-us-slide-ul"
                  dangerouslySetInnerHTML={{ __html: slide?.list }}
                ></div>
              </div>
              <div className="flex-1">
                <img
                  src={slide?.image}
                  alt=""
                  className="md:w-3/4  lg:w-auto"
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default SliderSection;
