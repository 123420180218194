import { useState } from "react";
import GetLinks from "constant/HeaderLinks";

// component
import RegularButton from "./NavButtons/RegularButton";
import SubButton from "./NavButtons/SubButton";

const MobileNavList = ({ navIsOpen, onMobileHandler }) => {
  const [openSubNav, setOpenSubNav] = useState(null);

  const handleOpenSubNav = (index) => {
    setOpenSubNav(index);
  };

  const handleCloseSubNav = () => {
    setOpenSubNav(null);
  };

  const navLinks = GetLinks();

  return (
    <div
      className={`${
        navIsOpen
          ? "translate-x-[0%] opacity-100 select-auto visible"
          : "-translate-x-[100%] opacity-0 select-none invisible"
      } absolute top-0 left-0  bg-blueFont min-w-full h-[100vh] transition-translate  ease-in-out duration-500 p-6 overflow-x-hidden `}
    >
      <nav className="w-full flex pt-24 h-full ">
        <ul className="w-full flex flex-col gap-y-4">
          {navLinks?.map((link, index) =>
            link?.megaNav ? (
              <SubButton
                onMobileHandler={onMobileHandler}
                key={index}
                handleClose={handleCloseSubNav}
                handleOpen={handleOpenSubNav}
                openSubNav={openSubNav}
                index={index}
                data={link}
              />
            ) : (
              <RegularButton
                onMobileHandler={onMobileHandler}
                key={index}
                index={index}
                text={link.text}
                link={link.url}
              />
            )
          )}
        </ul>
      </nav>
    </div>
  );
};

export default MobileNavList;
