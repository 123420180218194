import React from "react";
import Container from "components/Container/Container";
const Values = ({ data }) => {
  return (
    <section className="mt-20 mb-44">
      <Container>
        <div className="flex flex-col lg:flex-row items-stretch justify-between gap-14">
          {data?.map(({ title, text }, index) => (
            <div
              className="flex-1 border p-4 rounded-xl flex flex-col justify-center"
              key={index}
            >
              <h6 className="font-audio text-blueFont text-xl mb-2">{title}</h6>

              <div
                className="font-mono text-lg text-font"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Values;
