import { useState, useEffect } from "react";
// components
import MobileButton from "./MobileButton";
import MobileNavList from "./MobileNavList";

const MobileNav = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);

  const navOpenHandler = () => {
    setNavIsOpen((cur) => !cur);
  };

  useEffect(() => {
    if (navIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [navIsOpen]);

  return (
    <div className="xl:hidden">
      <MobileButton active={navIsOpen} onClick={navOpenHandler} />
      <MobileNavList navIsOpen={navIsOpen} onMobileHandler={navOpenHandler} />
    </div>
  );
};

export default MobileNav;
