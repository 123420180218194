import React from "react";

const SelectInput = (props) => {
  const {
    label,
    options,
    name,
    id,
    placeholder,
    value,
    onChange = () => {},
    onBlur = () => {},
    hasError = false,
    errorMessage = "error",
  } = props;
  return (
    <span className="flex flex-col w-full">
      <label className="text-white text-lg">{label}</label>

      <select
        // multiple
        className="bg-lightBlue p-2 w-full  focus:outline-blueFont text-white font-medium "
        onChange={onChange}
        name={name}
        value={value}
        id={id}
      >
        {options?.map((option, index) => (
          <option key={index} value={option.value}>
            {option}
          </option>
        ))}
      </select>

      {/* {isError && error && (
        <p className="text-sm text-red-500 mt-1">This field is required</p>
      )} */}
    </span>
  );
};

export default SelectInput;
