import Container from "components/Container/Container";

const SmallHero = ({ title, subTitle }) => {
  return (
    <section className="bg-blueFont h-[30vh] md:h-[40vh] ">
      <Container className="h-[30vh] md:h-[40vh] ">
        <div className="h-[30vh] md:h-[40vh] flex flex-col justify-center pt-14 lg:w-3/4">
          <p className="text-white">{subTitle}</p>
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-audio text-lightGreen">
            {title}
          </h1>
        </div>
      </Container>
    </section>
  );
};

export default SmallHero;
