import { useContext } from "react";

// components
import BlogLanding from "./BlogLanding/BlogLanding";

import SmallHero from "components/UI/Heros Section/SmallHero";
// slider
import MediaSlider from "../Media Slider/MediaSlider";

import { NewsContext } from "context/Media/NewsContext";
const Blog = () => {
  const { fullNewsContent, isLoading, isRequestError } =
    useContext(NewsContext);
  return (
    <main>
      <SmallHero title={"Media"} />
      <BlogLanding />

      <MediaSlider
        isNews={true}
        isError={isRequestError}
        isLoading={isLoading}
        title={"Latest news"}
        data={fullNewsContent}
      />
    </main>
  );
};

export default Blog;
