import Container from "components/Container/Container";
import React from "react";
import { Link } from "react-router-dom";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";
const Header = ({ title, banner, isSolution }) => {
  return (
    <section className="pt-primary lg:pt-mega pb-primary">
      <Container>
        <div>
          <Link
            to={`${isSolution ? "/solutions" : "/capabilities"}`}
            className="uppercase text-lg text-lightGreen font-[600] hover:underline flex items-center gap-x-2"
          >
            <CaretLeft />
            {`${isSolution ? "solution" : "capabilities"}`}
          </Link>
          <p className="text-2xl ss:text-3xl lg:text-4xl font-audio font-[700] text-white mt-2 uppercase">
            {title}
          </p>
        </div>
        {/* <span className="text-lightGreen flex items-center gap-x-2 font-[600] mt-secondary lg:mt-primary">
          <Link to={"/reach-out"}>Reach out</Link>
          <CaretRight />
        </span> */}
      </Container>

      <div className="mt-secondary lg:w-[65%]">
        <img src={banner} alt="" className="w-full h-full" />
      </div>
    </section>
  );
};

export default Header;
