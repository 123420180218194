import React, { createContext, useState, useEffect } from "react";
import GetContent from "utility/GetContent";

export const PartnersContext = createContext();

export const PartnersProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestError, setIsRequestError] = useState(false);

  const fetchPartnersData = async () => {
    setIsLoading(true);
    setIsRequestError(false);
    try {
      const data = await GetContent("partners");
      return data;
    } catch (error) {
      setIsRequestError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PartnersContext.Provider
      value={{ fetchPartnersData, isLoading, isRequestError }}
    >
      {children}
    </PartnersContext.Provider>
  );
};
