import React from "react";
import "./mobileButtonStyle.css";

const MobileButton = ({ onClick, active }) => {
  return (
    <button
      onClick={onClick}
      className={`relative z-[100] MobileBtnBox flex flex-col gap-y-1.5 ${
        active ? "isActive" : ""
      }`}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default MobileButton;
