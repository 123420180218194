import React from "react";
import { Link } from "react-router-dom";
const ItemListDisplay = ({ data, isSlider, isSolution }) => {
  return (
    <Link
      to={`/display/${isSolution ? "solution" : "capabilties"}/${data?.slug}`}
      className={`solution-bg flex flex-col bg-blueFont relative p-6 h-[400px] group overflow-hidden transition ease-in duration-300  ${
        isSlider ? "" : "hover:scale-[1.05]"
      }`}
    >
      <div className="absolute left-0 top-0 w-full h-full bg-[#00000069] z-[10] lg:hidden"></div>
      <div className="absolute left-0 top-0 h-full w-full overflow-hidden">
        <img
          className="transition ease-in duration-300 w-full h-full group-hover:scale-[1.8] group-hover:opacity-[50%] group-hover:blur-[75px] group-hover:translate-y-[30%] group-hover:translate-x-[30%] object-cover"
          src={data?.image}
          alt=""
        />
      </div>
      <div className="transparent-bg absolute left-0 top-0 h-full w-full transition ease-in duration-300 opacity-0 group-hover:opacity-100"></div>
      <h2 className="text-xl font-bold text-white relative z-[100]  ">
        {data?.title}
      </h2>

      {data?.list && !data.text && (
        <div
          className="solution-list mt-6 relative z-[100] text-white transition ease-in duration-300 lg:opacity-0 lg:translate-x-[150%] lg:group-hover:translate-x-[0%] lg:group-hover:opacity-100 h-full flex items-center "
          dangerouslySetInnerHTML={{
            __html: Array.isArray(data.list) ? data.list[0].list : data.list,
          }}
        />
      )}
      {data?.text && (
        <div
          className="solution-list mt-6 relative z-[100] text-white transition ease-in duration-300 lg:opacity-0 lg:translate-x-[150%] lg:group-hover:translate-x-[0%] lg:group-hover:opacity-100 h-full flex items-center "
          dangerouslySetInnerHTML={{
            __html:
              data?.text.length > 150
                ? data?.text.slice(0, 150) + "..."
                : data?.text,
          }}
        />
      )}

      <div className="mt-auto transition ml-auto ease-in duration-300 lg:translate-y-[200%] lg:group-hover:translate-y-[0%]">
        <p className="text-white relative ">See more</p>
      </div>
    </Link>
  );
};

export default ItemListDisplay;
