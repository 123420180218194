import Container from "components/Container/Container";
import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
import { useState, useEffect } from "react";

const JoinUs = () => {
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData("joined-us-info");
      setData(response[0]);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  if (loading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <main>
        <div className="py-16 lg:py-20">
          <div className="flex-1 ">
            <img className="w-full h-full" src={data?.image} alt="" />
          </div>
          <Container>
            <h1 className="text-blueFont font-mono font-semibold text-4xl my-6">
              {data?.title}
            </h1>
            <div className="flex flex-col flex-col-reverse lg:flex-row items-center gap-8 lg:gap-16 ">
              <div className="flex-1 ">
                <div
                  className="font-mono font-medium"
                  dangerouslySetInnerHTML={{ __html: data?.text }}
                />

                <div className="mt-10">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={"https://careers.midisgroup.com"}
                    className="  px-8 py-2 transition ease-in duration-300 border text-white bg-blueFont rounded-md hover:bg-white hover:text-blueFont"
                  >
                    Join Here
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </main>
    );
  }
};

export default JoinUs;
