import React, { useState } from "react";
import bgImage from "assests/image/bg-content-2.png";
// import bgImage from "assests/image/bg-content.png";
import { CaretDown, CaretUp } from "@phosphor-icons/react";

const Description = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const fullText = `${text}`;
  const truncatedText = fullText.substring(0, 500);
  const isTruncatable = fullText.length > 500;
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section>
      <div className="flex">
        <div className="hidden lg:flex flex-1 ">
          <img className="w-3/4 max-h-[300px] " src={bgImage} alt="" />
        </div>

        <div className="flex-1  lg:-ml-[50%] lg:mt-[7%] px-[1.3rem] md:px-[3.2rem] lg:px-0">
          <p
            className="lg:w-3/4 text-white "
            dangerouslySetInnerHTML={{
              __html: isExpanded
                ? fullText
                : truncatedText + (isTruncatable ? "..." : ""),
            }}
          ></p>
          {isTruncatable && (
            <div className="flex items-center w-3/4 gap-x-10 mt-6">
              <div className="flex-1 h-[1px] bg-white"></div>
              <button
                onClick={handleToggle}
                className="text-white flex items-center gap-x-2"
              >
                {isExpanded ? "See less" : "See more"}{" "}
                {isExpanded ? <CaretUp /> : <CaretDown />}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Description;
