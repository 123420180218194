import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Header from "./Header/Header";
import Description from "./description/Description";
import DisplayData from "./DisplayData/DisplayData";
import Capabilties from "Pages/Home/Capabilties/Capabilties";
import SpecialData from "./SpecialData/SpecialData";

import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";

const DetailedDisplayScreen = () => {
  const { slug } = useParams();
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData(`capabilities-info/${slug}`);
      setData(response?.data);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [slug]);

  if (loading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <main className="bg-blueFont">
        <Header title={data?.title} banner={data?.banner} />
        <Description text={data?.text} />

        {slug === "service-framework-and-cx" && <SpecialData />}
        {data.info?.info_data.length > 0 && (
          <DisplayData slug={slug} data={data?.info} />
        )}
        <Capabilties data={data?.other_capabilities} />
      </main>
    );
  }
};

export default DetailedDisplayScreen;
