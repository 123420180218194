import aboutImage from "assests/image/Home/about/new.jpg";

const aboutContent = {
  subTitle: "who we are",
  title: " we design the proper business path into the future",
  text: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo natus est rem, itaque ullam dignissimos dolorem cum, unde quam, aperiam iusto vitae. Deserunt tenetur, ut dolores nostrum totam quisquam rerum!",
  image: aboutImage,
};

export default aboutContent;
