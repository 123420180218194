import React from "react";

const Input = (props) => {
  const {
    label,
    type,
    name,
    id,
    placeholder,
    value,
    onChange = () => {},
    onBlur = () => {},
    hasError = false,
    errorMessage = "error",
  } = props;

  return (
    <span className={"flex flex-col w-full"}>
      <label className="text-white text-lg">{label}</label>
      <input
        required
        className="bg-lightBlue p-2 w-full  focus:outline-blueFont text-white font-medium "
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {hasError && (
        <p
          className={`text-red-500 text-sm my-1 ${
            hasError ? " opacity-1 " : " opacity-0"
          }`}
        >
          {errorMessage}
        </p>
      )}
    </span>
  );
};

export default Input;
