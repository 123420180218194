import React from "react";

const SectionHeader = ({
  subTitle,
  title,
  titleColor,
  text,
  subTitleColor,
}) => {
  return (
    <div className="md:w-3/4 lg:w-1/2 mb-small md:mb-secondary ">
      <h5 className={`capitalize font-medium mb-2 text-${subTitleColor}`}>
        {subTitle}
      </h5>
      <h2
        className={`text-${titleColor} font-audio text-2xl md:text-3xl font-medium first-letter:uppercase  mb-small`}
      >
        {title}
      </h2>

      <p>{text}</p>
    </div>
  );
};

export default SectionHeader;
