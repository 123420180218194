import Container from "components/Container/Container";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
import NewsParent from "./NewsParent";
const NewsLanding = ({ data = {}, isError, isLoading }) => {
  return (
    <section className="mt-secondary mb-primary">
      <Container>
        <div className="my-secondary">
          <h2 className="font-audio text-3xl text-blueFont ">
            Other Articles and News
          </h2>
        </div>

        {isLoading && <IsLoading />}
        {isError && <IsError />}
        {!isLoading && !isError && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
            {data?.latestNews?.map((news, index) => (
              <NewsParent
                key={index}
                image={news.image}
                title={news.title}
                date={news.date}
                slug={news.slug}
              />
            ))}
          </div>
        )}
      </Container>
    </section>
  );
};

export default NewsLanding;
