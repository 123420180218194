import Container from "components/Container/Container";
import { useEffect, useState } from "react";

import ItemListDisplay from "components/UI/ItemListDisplay";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
const Capabilities = () => {
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const { fetchData } = useFetch();

  const getSolutionData = async (slug) => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData("capabilities-info");
      setData(response?.capabilities);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSolutionData();
  }, []);

  if (loading) return <IsLoading />;
  else if (isError) return <IsError />;
  if (data) {
    return (
      <main className="relative bg-blueFont">
        <Container>
          <div className="pt-mega">
            <h1 className="text-white text-3xl font-semibold">Capabitlies</h1>
            {/* <p className="text-4xl font-bold text-white">
              Title here for this page
            </p> */}
          </div>
        </Container>

        <div className="big-container pb-primary pt-secondary grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {data?.map((item, index) => (
            <ItemListDisplay data={item} key={index} />
          ))}
        </div>
      </main>
    );
  }
};

export default Capabilities;
