import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Header from "./Header/Header";
import Description from "./description/Description";
import DisplayData from "./DisplayData/DisplayData";
import SolutionSLider from "Pages/Home/Solution/SolutionSLider";
import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
import Container from "components/Container/Container";

const DetailedDisplayScreenSolution = () => {
  const { slug } = useParams();
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData(`solution/${slug}`);
      setData(response);
      console.log(response);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [slug]);

  if (loading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <main className="bg-blueFont">
        <Header isSolution={true} title={data?.title} banner={data?.banner} />
        <Description text={data?.text} />
        <DisplayData isSolution={true} data={data?.info} />
        <div className="py-secondary">
          <div className="big-container">
            <p className="text-white text-4xl mb-4 font-[600]">
              Other Solutions
            </p>
          </div>
          <SolutionSLider data={data?.other_solutioin} />
        </div>
      </main>
    );
  }
};

export default DetailedDisplayScreenSolution;
