// UI
import SectionHeader from "components/UI/SectionHeader";
import Container from "components/Container/Container";

import SolutionSLider from "./SolutionSLider";

const Solution = ({ data }) => {
  return (
    <section className="mt-secondary  lg:my-primary">
      <div className="big-container">
        <SectionHeader
          title={"a journey to business transformation"}
          subTitle={"solutions"}
          titleColor={"blueFont"}
          subTitleColor={"lightGreen"}
        />
      </div>

      <SolutionSLider data={data} />
    </section>
  );
};

export default Solution;
