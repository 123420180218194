import { useState } from "react";
import BASE_URL from "utility/BaseUrl";
const useFetch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [bySlugLoading, setBySlugLoading] = useState(false);

  const fetchData = async (url) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${BASE_URL}/${url}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
      return result;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataBySlug = async (slug) => {
    setBySlugLoading(true);
    setError(null);
    try {
      const response = await fetch(`${BASE_URL}/${slug}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setBySlugLoading(false);
    }
  };

  return { data, loading, error, fetchData, fetchDataBySlug, bySlugLoading };
};

export default useFetch;
