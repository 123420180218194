import { useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
// context
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Autoplay, Keyboard, Navigation } from "swiper/modules";
// icon
import { CaretRight, CaretLeft } from "@phosphor-icons/react";

// style
import "./SolutionStyle.css";

import ItemListDisplay from "components/UI/ItemListDisplay";

const SolutionSLider = ({ data }) => {
  const [swiper, setSwiper] = useState(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="solution-container relative">
      <button
        ref={prevRef}
        className="absolute top-1/2 -translate-y-1/2 left-6 z-[100] solution-prev-button hidden md:block"
      >
        <CaretLeft />
      </button>
      <button
        ref={nextRef}
        className="absolute top-1/2 -translate-y-1/2 right-6 z-[100] solution-next-button hidden md:block"
      >
        <CaretRight />
      </button>
      <div className="big-container">
        <Swiper
          speed={1000}
          slidesPerView={1.1}
          spaceBetween={8}
          modules={[Navigation, Autoplay, Keyboard]}
          keyboard={{
            enabled: true,
          }}
          navigation={{
            clickable: true,
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
          breakpoints={{
            578: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 14,
            },
            992: {
              slidesPerView: 4,
              slidesPerGroup: 1,
            },
          }}
        >
          {data?.map((sol, index) => (
            <SwiperSlide key={index}>
              <ItemListDisplay isSolution={true} isSlider={true} data={sol} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SolutionSLider;
