import SmallHero from "components/UI/Heros Section/SmallHero";
import Map from "./Map/Map";
import Form from "./Form/Form";
import ContactInfo from "./ContactInfo";

const ContactUs = () => {
  return (
    <main>
      <SmallHero title={"Reach out"} />
      <ContactInfo />
      <Form />
    </main>
  );
};

export default ContactUs;
