import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
import { useState, useEffect } from "react";

const GetLinks = () => {
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [solutionData, setSolutionData] = useState([]);
  const [capabiltiesData, setCapabiltiesnData] = useState([]);

  const { fetchData } = useFetch();

  const getSolutions = async () => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData("solution");
      setSolutionData(response?.solutions);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getCapabilties = async () => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData("capabilities-info");
      setCapabiltiesnData(response?.capabilities);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSolutions();
    getCapabilties();
  }, []);

  const updatedNavLinks = [
    {
      text: "what we do",
      url: "",
      megaNav: true,
      links: [
        {
          title: "Solutions",
          path: "/solutions",
          data: solutionData,
          sol: true,
        },
        {
          title: "Capabilities",
          path: "/capabilities",
          data: capabiltiesData,
        },
      ],
    },
    {
      text: "who we are",
      url: "/who-we-are",
    },

    {
      text: "Join us",
      url: "/join-us",
    },
    {
      text: "reach out",
      url: "/reach-out",
    },
    // {
    //   text: "Partners",
    //   link: "/partners",
    // },
    // {
    //   text: "News",
    //   link: "/news",
    // },
    // {
    //   text: "Blog",
    //   link: "/blog",
    // },
  ];

  return updatedNavLinks;
};

export default GetLinks;
