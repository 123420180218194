import Container from "components/Container/Container";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
// icons
import { ArrowRight } from "@phosphor-icons/react";

const SuccessStory = ({ data = {}, isError, isLoading }) => {
  return (
    <section className="mt-secondary">
      <Container>
        {isLoading && <IsLoading />}

        {isError && <IsError />}

        {!isLoading && !isError && (
          <div className="flex flex-col lg:flex-row gap-10">
            <div className="flex-1">
              <img
                src={data?.image}
                alt=""
                className="w-[65%] md:w-1/2 lg:w-3/4"
              />
            </div>
            <div className="flex-2 ">
              <div className="lg:w-[90%] border-b-2 pb-bigSmall">
                {/* main title */}
                <h2 className="font-audio text-2xl md:text-3xl text-blueFont mb-3">
                  {data?.mainTitle}
                </h2>
                {/* text */}
                <p>{data?.text}</p>
              </div>

              <div className="lg:w-[90%]">
                {/* story title */}
                <h4 className="font-audio text-2xl md:text-3xl text-blueFont mb-2 pt-bigSmall">
                  {data?.storyTitle}
                </h4>
                {/* contentSection */}
                <div className="flex flex-col gap-y-small mt-small">
                  {data?.contentSections?.map((cont) => (
                    <div className="flex gap-x-4">
                      <span className="flex items-center h-fit mt-1 text-blueFont">
                        <ArrowRight size={16} weight="bold" />
                      </span>
                      <span>
                        {/* sectionTitle */}
                        <h6 className="text-xl md:text-2xl font-semibold text-lightGreen mb-1">
                          {cont?.sectionTitle}
                        </h6>
                        {/* section text */}
                        {cont?.sectionText.map((text) => (
                          <p>{text}</p>
                        ))}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </section>
  );
};

export default SuccessStory;
