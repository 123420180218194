import { LinkedinLogo, YoutubeLogo } from "@phosphor-icons/react"; // Ensure you import your icons correctly

const SectionLinks = () => {
  const social = [
    {
      header: "Keep in Touch",
      icons: [
        {
          icon: <LinkedinLogo color="#0077B5" />,
          url: "https://www.linkedin.com/company/mdscomputers/",
        },
        // {
        //   icon: <YoutubeLogo color="#FF0000" />,
        //   url: "https://www.youtube.com/channel/UCT0qCPEzh2-a-vthiKWAsQw",
        // },
      ],
    },
  ];

  return (
    <div>
      {social.map((cont, index) => (
        <div key={index}>
          <h4 className="text-gray-400 text-lg mb-1">{cont.header}</h4>
          <div className="flex items-center gap-x-2">
            {cont.icons.map((icon, indx) => (
              <a
                target="_blank"
                href={icon.url}
                rel="noreferrer"
                key={indx}
                className="text-lightGreen text-2xl"
              >
                {icon.icon}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SectionLinks;
