import Container from "components/Container/Container";

const Features = ({ data = {} }) => {
  return (
    <section className="">
      <Container className="border-b-2 border-lightGreen pb-primary">
        <div className="lg:text-center lg:w-3/4  mx-auto mb-4">
          <h2 className="text-2xl md:text-3xl text-blueFont font-audio mb-2">
            {data?.title}
          </h2>
          <p className="font-medium text-[#414042]">{data?.text}</p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 xl:grid-cols-4 justify-between">
          {data?.list &&
            data?.list?.map((fet, index) => (
              <div
                key={index}
                className="flex flex-col py-4 items-start lg:items-center border-b lg:border-b-0 lg:border-l lg:py-2 lg:last:border-r mt-14"
              >
                <div className="">
                  <img
                    src={fet.image}
                    alt=""
                    className="w-full h-full object-fill"
                  />
                </div>
                <div className="lg:text-center lg:px-4 mt-4">
                  <h6 className="text-2xl font-semibold text-lightGreen">
                    {fet.title}
                  </h6>
                  <p className="text-sm mt-1 font-[500] text-[#414042]">
                    {fet.text}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </Container>
    </section>
  );
};

export default Features;
