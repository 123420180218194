// components
import CustomerHero from "./Hero/CustomerHero";
import CustomerLanding from "./landing/CustomerLanding";
// utilies

// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";

const Customer = () => {
  const { data: customerData, loading, error } = useFetch("customer");

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (customerData) {
    return (
      <main>
        <CustomerHero data={customerData?.hero} />
        <CustomerLanding data={customerData?.images} />
      </main>
    );
  }
};

export default Customer;
