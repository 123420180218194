import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
// UI
import Container from "components/Container/Container";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./MediaSliderStyle.css";

// import required modules
import { Pagination } from "swiper/modules";

const MediaSlider = ({ title, data = [], isLoading, isError, isNews }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className=" bg-[#F2F2F2] py-secondary">
      <Container>
        <div className="mb-bigSmall">
          <h4 className="font-audio text-3xl text-blueFont">{title}</h4>
        </div>
        <Swiper
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mediaSlider"
        >
          {isError && <IsError />}
          {isLoading && <IsLoading />}
          {!isLoading &&
            !isError &&
            data?.map((item, index) => (
              <SwiperSlide key={index}>
                <Link
                  onClick={scrollToTop}
                  to={`${isNews ? `/news/${item.slug}` : "/blog"}`}
                  className=""
                >
                  <div className="h-52">
                    <img
                      src={item.image}
                      alt=""
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className="my-4">
                    <p className="text-lightGreen font-medium">{item.date}</p>
                    <h6 className="text-xl text-blueFont font-medium">
                      {item.title}
                    </h6>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default MediaSlider;
