import { useContext, useEffect, useState } from "react";
// UI
import MediaSlider from "../Media Slider/MediaSlider";
// components
import Hero from "./NewHero/Hero";
import NewsLanding from "./NewsLanding/NewsLanding";
// context
import { NewsContext } from "context/Media/NewsContext";
import { BlogContext } from "context/Media/BlogContext";

const News = () => {
  const { newsContent, latestNews, isLoading, isRequestError } =
    useContext(NewsContext);
  const { getBlogData } = useContext(BlogContext);
  const [blogContent, setBlogContent] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getBlogData();
      setBlogContent(data);
    };

    getData();
  }, []);
  return (
    <main>
      <Hero isLoading={isLoading} isError={isRequestError} data={latestNews} />
      <NewsLanding
        data={newsContent}
        isLoading={isLoading}
        isError={isRequestError}
      />

      <MediaSlider
        title={"Our Blog "}
        data={blogContent?.blogs}
        isLoading={isLoading}
        isError={isRequestError}
      />
    </main>
  );
};

export default News;
