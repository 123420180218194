import { useContext, useState, useEffect } from "react";
// UI
import IsError from "components/UI/Request Handling/IsError";
import IsLoading from "components/UI/Request Handling/IsLoading";

// components
import HalfHero from "components/UI/Heros Section/HalfHero";
import PartnerList from "./Partners list/PartnerList";
import PartnersSlider from "./Partners slider/PartnersSlider";

import { PartnersContext } from "context/Partners/PartnersContext";
const Partners = () => {
  const { fetchPartnersData, isLoading, isRequestError } =
    useContext(PartnersContext);

  const [partnersData, setPartnersData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchPartnersData();
      setPartnersData(data);
    };

    getData();
  }, []);

  if (isLoading) return <IsLoading />;
  else if (isRequestError) return <IsError />;
  else if (partnersData) {
    return (
      <main>
        <HalfHero title={"Technology partners"} bg="partners-bg" />
        <PartnerList data={partnersData?.partners} />
        <PartnersSlider data={partnersData?.otherPartners} />
      </main>
    );
  }
};

export default Partners;
