import { Link } from "react-router-dom";

const NewsParent = ({ image, date, title, slug }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Link onClick={scrollToTop} to={`${slug}`}>
      <div>
        <div>
          <img src={image} alt="" className="h-full w-full" />
        </div>
        <div>
          <span className="text-lightGreen mt-3 block">{date}</span>
          <p className="text-xl font-semibold text-blueFont">{title}</p>
        </div>
      </div>
    </Link>
  );
};

export default NewsParent;
