import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// UI
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "swiper/css/effect-fade";
import "./Slider.css";

// import required modules
import { Autoplay, Pagination, EffectFade } from "swiper/modules";

const Hero = ({ data = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Custom event listener to track logical active index
  useEffect(() => {
    const handleSlideChange = () => {
      setActiveIndex(swiperInstance.realIndex);
    };

    if (swiperInstance) {
      swiperInstance.on("slideChange", handleSlideChange);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", handleSlideChange);
      }
    };
  }, [swiperInstance]);

  const loadingImage = () => {
    setIsImageLoading(false);
  };

  return (
    <section className="w-full hero-section  overflow-hidden">
      {isImageLoading && (
        <div className="hero-section relative bg-blueFont"></div>
      )}
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        effect={"fade"}
        speed={1000}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, EffectFade]}
        onSwiper={setSwiperInstance}
        className="heroSlider"
      >
        {data?.map((cont, indx) => (
          <SwiperSlide key={indx} className="slider-parent">
            <div className="relative content-parent">
              <div
                className={`image-parent relative zoom ${
                  activeIndex === indx ? "zoomed" : ""
                }`}
              >
                <img
                  src={cont.image}
                  onLoad={loadingImage}
                  className={`hero-image ${
                    isImageLoading ? "hidden" : "block"
                  }`}
                  alt=""
                />
              </div>
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hero-conent text-center ">
                <h1
                  className={`text-white text-3xl lg:text-[2.5rem] leading-[1.1]  font-audio mb-4 uppercase`}
                >
                  {cont.title}
                </h1>

                <p className="text-white text-lg ">{cont.subTitle}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      )
    </section>
  );
};

export default Hero;
