import FirstSection from "./Components/FirstSection";
import SecondSection from "./Components/SecondSection";
import SliderSection from "./Components/SliderSection";

// bg images
import "./LandingStyle.css";

const Landing = ({ data }) => {
  return (
    <section className="">
      <div className="relative">
        <FirstSection data={data?.introduction} />
      </div>

      <div className="relative bg-landing  -mt-[16rem]  ">
        <SecondSection data={data?.about} />
        <SliderSection data={data?.slider} />
      </div>
    </section>
  );
};

export default Landing;
