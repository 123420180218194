// UI
import Container from "components/Container/Container";
import Spinner from "components/UI/Spinner";
// form Component
import Input from "form/Components/Inputs/Input";
import Textarea from "form/Components/Inputs/Textarea";
import SelectInput from "form/Components/Inputs/SelectInput";
// hook
import useInput from "form/Components/Hooks/user-input";
import { useState } from "react";

import BASE_URL from "utility/BaseUrl";

const Form = () => {
  const clearErrorMessage = () => {
    setIsNotValid(false);
  };

  const {
    value: firstNameInput,
    isValid: firstNameIsValid,
    HasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    inputFocusHandler: firstNameFocusHanlder,
    isFocus: firstNameIsFocus,
    reset: firstNameReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: companyInput,
    isValid: companyIsValid,
    HasError: companyHasError,
    inputChangeHandler: companyChangeHandler,
    inputBlurHandler: companyBlurHandler,
    inputFocusHandler: companyFocusHanlder,
    isFocus: companyIsFocus,
    reset: companyReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: emailInput,
    isValid: emailIsValid,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    inputFocusHandler: emailFocusHanlder,
    isFocus: emailIsFocus,
    reset: emailReset,
  } = useInput((value) => value.trim() !== "");
  const {
    value: messageInput,
    isValid: messageIsValid,
    HasError: messageHasError,
    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    inputFocusHandler: messageFocusHanlder,
    isFocus: messageIsFocus,
    reset: messageReset,
  } = useInput((value) => value.trim() !== "");

  // identity
  const subjectOption = [
    "Website Feedback",
    " Product or Service Inquiries",
    "Customer Support",
    "Partnership Opportunities",
    "Employment Opportunities",
    "Complaints",
    "Suggestions",
    "Other",
  ];
  const [subjectInput, setSubjectInput] = useState("");
  const subjectChangeHandler = (e) => {
    setSubjectInput(e.target.value);
  };

  let formIsValid =
    firstNameIsValid && emailIsValid && subjectInput && messageIsValid;

  const [isNotValid, setIsNotValid] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const clearInputs = () => {
    firstNameReset();
    companyReset();
    messageReset();
    emailReset();
    setSubjectInput("");
  };
  const submitFormHandler = async () => {
    if (!formIsValid) {
      setIsNotValid(true);
      return;
    }

    // Ensure these variables hold the actual input values
    const formStatus = {
      full_name: firstNameInput,
      email: emailInput,
      company: companyInput,
      subject: subjectInput,
      message: messageInput,
    };

    try {
      setIsLoading(true);
      const response = await fetch(
        `https://mdscomputersae.twalab.live/api/contact-us-form`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formStatus),
        }
      );

      if (response.ok) {
        const responseData = await response.json(); // Optional: Get response data
        console.log("Success:", responseData);
        setFormStatus("success");
        clearInputs(); // Clear inputs on success
      } else {
        const errorText = await response.text(); // Log response text for debugging
        setFormStatus("failed");
      }
    } catch (error) {
      console.error("Catch Error:", error);
      setFormStatus("failed");
    } finally {
      setIsLoading(false);
    }

    // Reset formStatus after 3 seconds
    setTimeout(() => {
      setFormStatus(""); // Reset to initial state, or any other state you'd like
    }, 3000);
  };

  return (
    <section className="mt-secondary pb-secondary bg-blueFont">
      <Container>
        <div className="py-secondary text-center">
          <h2 className="text-4xl uppercase text-lightGreen font-audio text-center ">
            get in touch
          </h2>
          {isNotValid && (
            <p className="text-red-400 mt-2">
              Please ensure all required inputs are valid.
            </p>
          )}

          <p
            className={`text-center mt-2  ${
              formStatus === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            {formStatus === "success"
              ? "Your message has been successfully sent."
              : formStatus === "failed"
              ? "There was an error sending your message. Please try again."
              : ""}
          </p>
        </div>

        <form className="flex flex-col gap-6 lg:gap-10">
          <span className="flex flex-col md:flex-row items-center gap-y-6 md:gap-y-0 md:gap-x-10">
            <Input
              type={"text"}
              label={"Full Name *"}
              name={"firstName"}
              id={"firstName"}
              value={firstNameInput}
              onChange={(event) => {
                firstNameChangeHandler(event);
                clearErrorMessage();
              }}
              onBlur={firstNameBlurHandler}
              onFocus={firstNameFocusHanlder}
              isFocus={firstNameIsFocus}
              hasError={firstNameHasError}
              // errorMessage={inputFromMessage.text}
            />

            <Input
              label={"Email *"}
              type={"email"}
              name={"email"}
              id={"email"}
              value={emailInput}
              onChange={(event) => {
                emailChangeHandler(event);
                clearErrorMessage();
              }}
              onBlur={emailBlurHandler}
              onFocus={emailFocusHanlder}
              isFocus={emailIsFocus}
              hasError={emailHasError}
              // errorMessage={inputFromMessage.text}
            />
          </span>
          <span className="flex flex-col  md:flex-row items-center gap-y-6 md:gap-y-0 md:gap-x-10">
            <Input
              label={"Company"}
              type={"text"}
              name={"company"}
              id={"company"}
              value={companyInput}
              onChange={(event) => {
                companyChangeHandler(event);
                clearErrorMessage();
              }}
              onBlur={companyBlurHandler}
              onFocus={companyFocusHanlder}
              isFocus={companyIsFocus}
              hasError={companyHasError}
            />

            <SelectInput
              label={"Subject *"}
              name={"Subject"}
              id={"Subject"}
              options={subjectOption}
              value={subjectInput}
              onChange={subjectChangeHandler}
            />
          </span>
          <Textarea
            onChange={(event) => {
              messageChangeHandler(event);
              clearErrorMessage();
            }}
            value={messageInput}
            label={"Your Message *"}
            name={"message"}
            id={"message"}
          />
        </form>

        <div className="flex mt-10 items-center justify-between w-full">
          {/* <ReCAPTCHA sitekey="6LeOGb8pAAAAACZaUFc6v0KE4Z1HrjnMwKwY50Wm" /> */}

          <button
            onClick={submitFormHandler}
            className="text-white bg-lightGreen px-6 py-2 mt-secondary md:mx-auto w-max flex items-center justify-center text-lg"
          >
            {isLoading && <Spinner isWhite={true} />}
            Submit
          </button>
        </div>
      </Container>
    </section>
  );
};

export default Form;
