import React, { createContext, useState, useEffect } from "react";
import GetContent from "utility/GetContent";

export const SuccessContext = createContext();

export const SuccessProvider = ({ children }) => {
  const [slug, setSlug] = useState("");
  // success data
  const [successData, setSuccessData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestError, setIsRequestError] = useState(false);
  // story data
  const [storyData, setStoryData] = useState(null);
  const [storyIsLoading, setStoryIsLoading] = useState(false);
  const [storyRequestIsError, setStoryRequestIsError] = useState(false);

  const getSuccessData = async (slug) => {
    try {
      setIsLoading(true);
      setIsRequestError(false);
      if (slug) {
        const successData = await GetContent("success-stroy", slug);
        setSuccessData(successData);
      } else {
        const successData = await GetContent("success-stroy");
        setSuccessData(successData);
      }
    } catch (error) {
      setIsRequestError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getStoryDataHandler = async (slug) => {
    setSlug(slug);
    setStoryIsLoading(true);
    setStoryRequestIsError(false);
    try {
      const storyData = await GetContent("success-stroy", slug);
      setStoryData(storyData.successStories);
    } catch (error) {
      console.log(error);
      setStoryRequestIsError(true);
    } finally {
      setStoryIsLoading(false);
    }
  };

  return (
    <SuccessContext.Provider
      value={{
        slug,
        // success data
        successData,
        getSuccessData,
        isLoading,
        isRequestError,
        // story data
        storyData,
        getStoryDataHandler,
        storyIsLoading,
        storyRequestIsError,
      }}
    >
      {children}
    </SuccessContext.Provider>
  );
};
