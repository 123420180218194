import { useState, useEffect } from "react";

import { ArrowRight, Minus, CaretDown, CaretUp } from "@phosphor-icons/react";
const BlogCat = ({ data = [], onFilter }) => {
  const initialSelectedState = data.some((cat) => cat.selected === true);
  const [isSelected, setIsSelected] = useState(initialSelectedState);

  const [isContentVisble, setIsContentVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setIsSelected(data.some((cat) => cat.selected === true));
  }, [data]);

  const contentVisibleHanlder = () => {
    setIsContentVisible((cur) => !cur);
  };

  return (
    <div className="sm:w-3/4 md:w-1/2 lg:w-full flex flex-col gap-4 border  border-lightGreen rounded-[2rem] p-4">
      <div
        className={` text-center flex items-center transition ease-in duration-300 gap-x-2 lg:border-b-2 lg:pb-2 ${
          isSelected ? "justify-between" : "justify-start"
        } ${isContentVisble ? "border-b-2 pb-2" : ""} px-2 text-blueFont`}
      >
        <button className="lg:hidden" onClick={contentVisibleHanlder}>
          {" "}
          {isContentVisble && <CaretUp size={32} />}
          {!isContentVisble && <CaretDown size={32} />}
        </button>
        <h4 className="text-2xl lg:text-3xl font-audio ">Categories</h4>
        {isSelected && (
          <button onClick={() => onFilter(null)}>
            <Minus size={32} weight="bold" />
          </button>
        )}
      </div>

      <div
        className={`flex-col gap-6 ${
          isContentVisble ? "flex " : "hidden"
        } lg:flex `}
      >
        {data?.map((cat, index) => (
          <button
            key={index}
            onClick={() => {
              scrollToTop();
              onFilter(cat.slug);
            }}
            className="flex items-center text-center  gap-x-2"
          >
            <span
              className={`flex items-center  ${
                cat.selected ? "text-lightGreen" : "text-blueFont"
              }`}
            >
              <ArrowRight size={20} weight="bold" />
            </span>
            <span
              className={` text-lg font-medium ${
                cat.selected ? "text-lightGreen" : "text-blueFont"
              }`}
            >
              {cat.title}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default BlogCat;
