import Container from "components/Container/Container";
// components
import FooterLogo from "./Component/FooterLogo";
import FooterBorder from "./Component/FooterBorder";

// grid Items
import SectionLinks from "./Component/SectionLinks";
import SolutionFooter from "./Component/SolutionFooter";
import ContactUs from "./Component/ContactUs";
import CapabiltiesFooter from "./Component/CapabiltiesFooter";

const Footer = () => {
  return (
    <footer className=" ">
      <FooterBorder />
      <Container className="my-secondary">
        <div className="my-secondary">
          <FooterLogo />
        </div>

        <div className="footer-grid ">
          <SectionLinks />
          <SolutionFooter />
          <ContactUs />
        </div>
      </Container>

      <div className="bg-gray-300 py-6">
        <Container>
          <div className="text-sm font-[600] font-mono text-gray-700 gap-y-3 flex flex-col md:flex-row lg:items-center justify-between">
            <p>All rights reserved MDS Computers 2024</p>

            <p>
              Powered by{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="underline"
                href="https://www.dowgroup.com/"
              >
                Dow Group
              </a>
            </p>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
