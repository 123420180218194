import React, { createContext, useState, useEffect } from "react";
import GetContent from "utility/GetContent";
import { useLocation } from "react-router-dom";

export const SolutionContext = createContext();

export const SolutionProvider = ({ children }) => {
  const [allSolutions, setAllSolutions] = useState(null);
  const [mergedSolutions, setMergedSolution] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isRequestError, setIsRequestError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const getAllSolutions = async () => {
      setIsLoading(true);
      setIsRequestError(false);
      try {
        const allSolutionsData = await GetContent("solution");
        setAllSolutions(allSolutionsData);

        // Merge outherSolutions array with soulutions object
        if (allSolutionsData) {
          const mergedArray = [
            allSolutionsData?.soulutions,
            ...allSolutionsData?.outherSolutions,
          ];
          setMergedSolution(mergedArray);

          const slugFromURL = location.pathname.replace("/solutions/", "");

          if (slugFromURL) {
            const filteredSolutions = mergedArray?.filter(
              (sol) => sol.slug !== slugFromURL
            );
            setMergedSolution(filteredSolutions);
          } else {
            setMergedSolution(mergedArray);
          }
        }

        // update mergedSolution based on url,to remove the selectd solution from the slider
      } catch (error) {
        setIsRequestError(true);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    getAllSolutions();

    return () => {
      isMounted = false;
    };
  }, [location]);

  return (
    <SolutionContext.Provider
      value={{ isLoading, isRequestError, allSolutions, mergedSolutions }}
    >
      {children}
    </SolutionContext.Provider>
  );
};
