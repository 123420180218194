import { Link } from "react-router-dom";

const RegularButton = ({ text, link, onMobileHandler }) => {
  return (
    <li className="text-white uppercase text-lg border-b border-white pb-3">
      <Link onClick={onMobileHandler} to={link}>
        {text}
      </Link>
    </li>
  );
};

export default RegularButton;
