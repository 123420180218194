// components
import Hero from "./Hero/Hero";
import LazyLinkedInWrapper from "./LinkedIn/LazyLinkedInWrapper";
import Solution from "./Solution/Solution";
import About from "./About/About";
import Partners from "./Partners/Partners";
import Success from "./Success/Success";
import Technology from "./Technology/Technology";
import Capabilties from "./Capabilties/Capabilties";

// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
import { useState, useEffect } from "react";

const Home = () => {
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData("home");
      setData(response);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  console.log(data);

  if (loading) return <IsLoading />;
  else if (isError) return <IsError />;
  else if (data) {
    const hero = data?.hero;
    const whoWeAre = data?.whoWeAre;
    const capabilities = data?.capabilities;
    return (
      <main>
        <Hero data={hero} />
        {/* <LazyLinkedInWrapper /> */}
        <Solution data={data?.solutions} />
        <About data={whoWeAre} />
        <Partners data={data?.partners} />
        {/* <Success /> */}
        <Capabilties data={capabilities} />
        {/* <Technology data={capabilities} /> */}
      </main>
    );
  }
};

export default Home;
