import { useContext, useEffect, useState } from "react";
// UI
import SmallHero from "components/UI/Heros Section/SmallHero";
// success components
import SuccessStory from "./SuccessStory/SuccessStory";
import Gallery from "./Gallery/Gallery";
import SuccessSlider from "./SuccessSlider/SuccessSlider";
// utilities

import { SuccessContext } from "context/Succes/SucessContext";

import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";

const Success = () => {
  const [isGallery, setIsGallery] = useState(false);
  const {
    slug,
    // success data
    successData,
    getSuccessData,
    isLoading,
    isRequestError,
    // story data
    storyData,
    getStoryDataHandler,
    storyRequestIsError,
    storyIsLoading,
  } = useContext(SuccessContext);

  const loading = isLoading || storyRequestIsError;
  const error = isRequestError || storyIsLoading;

  useEffect(() => {
    if (storyData?.gallery.length > 0) {
      setIsGallery(true);
    } else {
      setIsGallery(false);
    }
  }, [storyData]);

  useEffect(() => {
    if (slug) {
      getStoryDataHandler(slug);
      getSuccessData(slug);
    } else {
      getSuccessData();
      getStoryDataHandler(slug);
    }
  }, [slug]);

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (storyData || successData) {
    return (
      <main>
        <SmallHero
          subTitle={"Home - Customer & Success Stories"}
          title={"Success Stories"}
        />
        <SuccessStory data={storyData} />
        {isGallery && <Gallery data={storyData?.gallery} />}
        <SuccessSlider
          isGallery={isGallery}
          data={successData?.otherSuccessStories}
          getStoryHandler={getStoryDataHandler}
        />
      </main>
    );
  }
};

export default Success;
