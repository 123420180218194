import React from "react";
import { Link } from "react-router-dom";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";

const SubButton = ({
  handleClose,
  handleOpen,
  index,
  openSubNav,
  data,

  onMobileHandler,
}) => {
  return (
    <>
      <button
        onClick={() => handleOpen(index)}
        className="text-white uppercase text-lg w-full flex items-center gap-x-3 border-b border-white pb-3"
      >
        {data.text}
        {openSubNav === index ? (
          <CaretLeft size={22} weight="bold" />
        ) : (
          <CaretRight size={22} weight="bold" />
        )}
      </button>
      <div
        className={`absolute p-6 w-full h-[100vh] bg-blueFont left-0 top-0 flex pt-24 h-full  flex-col transition ease duration-300 overflow-y-scroll ${
          openSubNav === index ? "translate-x-[0]" : "translate-x-[100%]"
        }`}
      >
        <button
          className="text-xl text-white flex items-center gap-x-2 border-b-2 border-lightGreen pb-2"
          onClick={handleClose}
        >
          <CaretLeft size={22} weight="bold" />
          Back
        </button>
        <ul className="flex flex-col gap-y-10 w-full mt-6">
          {data?.links?.map(({ title, data, sol, path }, pageIndex) => (
            <div
              className="text-white uppercase text-lg w-full flex flex-col   border-b border-white pb-3 w-full font-mano"
              key={pageIndex}
            >
              <Link
                to={path}
                onClick={() => {
                  handleClose();
                  onMobileHandler();
                }}
                className="text-xl font-bold mb-4 font-mono text-[#bbb]"
              >
                {title}
              </Link>

              {data?.map((p, index) => (
                <li key={index} className="text-sm  mb-3">
                  <Link
                    onClick={() => {
                      handleClose();
                      onMobileHandler();
                    }}
                    to={`/display/${sol ? "solution" : "capabilties"}/${
                      p.slug
                    }`}
                  >
                    {p.title}
                  </Link>
                </li>
              ))}
            </div>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SubButton;
