import Container from "components/Container/Container";

const CustomerLanding = ({ data = [] }) => {
  return (
    <section className="mt-secondary ">
      <Container>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 md:gap-16 lg:gap-20 items-center">
          {data?.map((image, index) => (
            <div key={index} className="w-full h-full ">
              <img src={image} alt="" />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default CustomerLanding;
