import footerLogo from "assests/image/Footer/footerLogo.png";
import { Link } from "react-router-dom";

const FooterLogo = () => {
  return (
    <Link to={"/"}>
      <div className="w-44 ">
        <img src={footerLogo} alt="" className="" />
      </div>
    </Link>
  );
};

export default FooterLogo;
