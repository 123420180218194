import Container from "components/Container/Container";

import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";

const HalfHero = ({ subTitle, title, bg, small, isLoading, isError }) => {
  return (
    <section className={`${bg} ${small ? "h-[55vh]" : "h-[70vh]"} `}>
      <Container className={`${small ? "h-[55vh]" : "h-[70vh]"}`}>
        <div
          className={`${
            small ? "h-[55vh]" : "h-[70vh]"
          } flex flex-col justify-center text-white `}
        >
          {isLoading && <IsLoading isWhite={true} />}
          {isError && <IsError isWhite={true} />}

          {!isLoading && !isError && (
            <>
              <h5 className="text-white">{subTitle}</h5>
              <h1 className="text-4xl md:text-5xl font-audio text-lightGreen md:w-3/4 ">
                {title}
              </h1>
            </>
          )}
        </div>
      </Container>
    </section>
  );
};

export default HalfHero;
