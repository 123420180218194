import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

// UI
import Container from "components/Container/Container";
import IsError from "components/UI/Request Handling/IsError";
import IsLoading from "components/UI/Request Handling/IsLoading";
// components
import BlogArtical from "./Components/BlogArtical";
import BlogCat from "./Components/BlogCat";
// context
import { BlogContext } from "context/Media/BlogContext";
import Spinner from "components/UI/Spinner";
const BlogLanding = () => {
  const location = useLocation();
  const { isLoading, isRequestError, getBlogData } = useContext(BlogContext);

  const [blogContent, setBlogContent] = useState([]);
  const [filterCat, setFilterCat] = useState("");

  const [displayCount, setDisplayCount] = useState(3);
  const hanldeFilterCat = (cat) => {
    setFilterCat(cat);
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getBlogData(filterCat || null);
      setBlogContent(data);
    };

    getData();
  }, [filterCat, location]);

  return (
    <section className="mt-secondary mb-primary">
      <Container>
        <div className="my-secondary">
          <h2 className="font-audio text-3xl text-blueFont">Our Blog</h2>
        </div>

        <div className="flex flex-col-reverse lg:grid lg:grid-cols-3 gap-10 mt-small">
          {isLoading && (
            <div className="lg:col-span-2  flex flex-col  ">
              <Spinner />
              <p>Loading data...</p>
            </div>
          )}
          {isRequestError && (
            <div className="lg:col-span-2">
              <IsError />
            </div>
          )}
          {!isLoading && !isRequestError && (
            <div className="lg:col-span-2 flex flex-col gap-14">
              {!isRequestError && (
                <div className="lg:col-span-2 flex flex-col gap-14">
                  {blogContent?.blogs
                    ?.slice(0, displayCount)
                    ?.map((cont, index) => (
                      <BlogArtical
                        key={index}
                        data={cont}
                        isError={isRequestError}
                        isLoading={isLoading}
                      />
                    ))}

                  {blogContent?.blogs?.length > displayCount && (
                    <button
                      className="uppercase w-3/4 mx-auto bg-[#F2F2F2] border border-[#03466ab5] p-2 rounded-md font-medium text-[#03466ae1] transition ease-in duration-300 hover:bg-[#f2f2f258]"
                      onClick={() => setDisplayCount(displayCount + 1)}
                    >
                      Load More
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="lg:col-span-1">
            <div className="sticky top-20 ">
              <BlogCat
                onFilter={hanldeFilterCat}
                data={blogContent?.categories}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BlogLanding;
