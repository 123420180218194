import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Container from "components/Container/Container";
import Logo from "./Components/Logo";
import Navigation from "./Components/navigation/Navigation";
import "./Style.css";

const Header = () => {
  const ref = useRef();
  const location = useLocation();
  const [headerIsSticky, setHeaderIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHeaderIsSticky(true);
      } else {
        setHeaderIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const isJoinUsRoute = location.pathname === "/join-us";
  return (
    <header
      ref={ref}
      className={`header  z-[100000]  relative ${
        headerIsSticky ? "stikcy" : ""
      }`}
    >
      <div
        className={`absolute left-0 top-0 w-full h-auto  z-[100000]  transition ease-in duration-300  ${
          headerIsSticky ? "bg-blueFont shadow-2xl border-b " : ""
        } ${isJoinUsRoute ? "bg-blueFont" : ""}`}
      >
        <Container>
          <div
            className={`flex items-center justify-between py-4 lg:py-0 ${
              headerIsSticky ? "" : "border-b border-[#ffffffb3]"
            }`}
          >
            <Logo />
            <div>
              <a
                className="text-end mb-3 pt-4 font-mano text-sm  font-medium uppercase text-[#ccc] hidden lg:block hover:underline"
                href="https://mdssigroup.com/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Part of MDS SI group
              </a>

              <Navigation />
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
