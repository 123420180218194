import { SolutionProvider } from "./Solution/SolutionContext";
import { NewsProvider } from "./Media/NewsContext";
import { BlogProvider } from "./Media/BlogContext";
import { SuccessProvider } from "./Succes/SucessContext";
import { PartnersProvider } from "./Partners/PartnersContext";

const ContextProvider = ({ children }) => {
  return (
    <SolutionProvider>
      <NewsProvider>
        <BlogProvider>
          <SuccessProvider>
            <PartnersProvider>{children}</PartnersProvider>
          </SuccessProvider>
        </BlogProvider>
      </NewsProvider>
    </SolutionProvider>
  );
};

export default ContextProvider;
