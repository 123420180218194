import { useEffect, useState } from "react";
import ItemListDisplay from "components/UI/ItemListDisplay";
import Container from "components/Container/Container";
import { Link } from "react-router-dom";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "components/UI/Request Handling/IsLoading";
import IsError from "components/UI/Request Handling/IsError";
const Solution = () => {
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const { fetchData } = useFetch();

  const getSolutionData = async (slug) => {
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await fetchData("solution");
      setData(response?.solutions);
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSolutionData();
  }, []);

  if (loading) return <IsLoading />;
  else if (isError) return <IsError />;
  else if (data) {
    return (
      <main className="relative bg-blueFont">
        <Container>
          <div className="pt-mega">
            <Link
              to={"/solutions"}
              className="text-white text-3xl font-semibold"
            >
              Solutions
            </Link>
            {/* <p className="text-4xl font-bold text-white">
              Title here for this page
            </p> */}
          </div>

          <div className="pb-primary pt-secondary grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 lg:gap-16">
            {data?.map((item, index) => (
              <ItemListDisplay isSolution={true} data={item} key={index} />
            ))}
          </div>
        </Container>
      </main>
    );
  }
};

export default Solution;
