import Container from "components/Container/Container";
import { useEffect, useState } from "react";
import performGetFormStatus from "utility/GetContent";
import { Phone, EnvelopeSimple, MapPin } from "@phosphor-icons/react";
import locationHandler from "utility/locationHandlder";

const ContactInfo = () => {
  const [loading, setLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const contactData = await performGetFormStatus("contact-info");

        setContactInfo(contactData?.contact);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const locationMaps = [
    "https://maps.app.goo.gl/Sd3z8mfoN8a7Lftp6?g_st=iw",
    "https://maps.app.goo.gl/yp9PRY8ABDaid9hs6?g_st=iw",
  ];

  return (
    <section className="mt-secondary mb-primary">
      <Container>
        <h2 className="text-3xl font-audio text-blueFont">Contact Info</h2>

        <div className="flex flex-col md:flex-row md:items-center gap-10 mt-10">
          {contactInfo?.map(
            ({ city, logo, location, street, phone_number, email }, index) => (
              <div key={index} className="flex-1">
                <div className="flex items-center gap-x-6">
                  <img className="w-28 h-28 object-contain" src={logo} alt="" />

                  <div>
                    <p className="font-mono font-bold text-lg text-[#555]">
                      {city}
                    </p>
                    <p className="font-mono font-bold text-[#555]">
                      {location}
                    </p>
                  </div>
                </div>

                <p className="my-4 font-mono text-[#555] w-3/4">{street}</p>

                <a
                  href={`tel:${phone_number}`}
                  className="flex items-center gap-x-2 mb-3 text-[#555] "
                >
                  <span className="bg-lightGreen p-1 rounded-full">
                    <Phone color="white" size={16} />
                  </span>
                  {phone_number}
                </a>

                <a
                  href={`mailto:${email}`}
                  className="flex items-center gap-x-2 mb-3 text-[#555] lowercase "
                >
                  <span className="bg-lightGreen p-1 rounded-full">
                    <EnvelopeSimple color="white" size={16} />
                  </span>
                  {email}
                </a>

                <button
                  className="flex items-center gap-x-2 underline font-medium text-blueFont"
                  onClick={() => locationHandler(locationMaps[index])}
                >
                  <span>
                    <MapPin size={26} />
                  </span>
                  View Location Map
                </button>
              </div>
            )
          )}
        </div>
      </Container>
    </section>
  );
};

export default ContactInfo;
