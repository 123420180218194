// UI
import Container from "components/Container/Container";

const SolutionHero = ({ data = {} }) => {
  return (
    <section className="customer-bg h-[90vh] " loading="eager">
      <Container className="h-[90vh]">
        <div className="h-[90vh] flex flex-col  text-white justify-center lg:w-1/2 ">
          <h5 className="text-white">Home - Customers & Success Stories</h5>

          <h1 className="text-4xl md:text-5xl font-audio text-lightGreen">
            {data.title}
          </h1>

          <span className="flex flex-col gap-y-2 mt-4 text-sm leading-relaxed">
            <p>{data.subTitle}</p>
          </span>
        </div>
      </Container>
    </section>
  );
};

export default SolutionHero;
