import "../LandingStyle.css";

const SecondSection = ({ data = {} }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center gap-bigSmall text-white pt-[24rem]  pb-24">
      <div className="flex-1">
        <img
          src={data?.image}
          alt=""
          className=" w-full h-full md:w-3/4 lg:w-full object-cover"
        />
      </div>

      <div className={`lg:flex-1 Container`}>
        <div className="lg:w-[85%]">
          <h2 className="text-3xl font-audio mb-3">{data?.title}</h2>
          <h6 className="text-lg font-medium">{data?.subTitle}</h6>
          <div
            className="font-philo text-white text text-sm mt-1"
            dangerouslySetInnerHTML={{ __html: data?.text }}
          />

          {/* <div
            className="about-us-slide-ul-why-us"
            dangerouslySetInnerHTML={{ __html: data?.text }}
          ></div> */}
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
