// slider
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import "./PartnerStyle.css";

const PartnersSlider = ({ data }) => {
  return (
    <div className="relative">
      <Swiper
        className="partnerSwipper "
        spaceBetween={20}
        slidesPerView={2}
        navigation={{
          clickable: true,
        }}
        speed={1000}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        breakpoints={{
          578: {
            slidesPerView: 3,
            slidesPerGroup: 4,
          },
          768: {
            slidesPerView: 5,
            slidesPerGroup: 5,
          },
        }}
      >
        {data?.map((image, index) => (
          <SwiperSlide
            key={index}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="w-36 h-24 md:w-24 md:h-24 mx-auto  flex items-center justify-center">
              <img
                src={image}
                alt=""
                className="h-full w-full image-slider object-contain "
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <button ref={prevRef} className="custom-prev-button">
            <CaretLeft />
          </button>
          <button ref={nextRef} className="custom-next-button">
            <CaretRight />
          </button> */}
    </div>
  );
};

export default PartnersSlider;
