import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./LatesNews.css";

// import required modules
import { Pagination } from "swiper/modules";

// icon
import { ArrowCircleRight } from "@phosphor-icons/react";

const LatestNewsSlider = ({ data = [] }) => {
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      spaceBetween={20}
      grabCursor={true}
      speed={800}
      modules={[Pagination]}
      className="LatesNewsSlider "
    >
      {data?.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="flex flex-col md:flex-row items-center gap-6 p-4 border border-lightGreen rounded-md">
            <div className="flex-1">
              <img src={slide.image} alt="" />
            </div>
            <div className="flex-1">
              <p className="text-lightGreen">{slide.date}</p>
              <h2 className="text-white font-medium text-2xl mb-2">
                {slide.title}
              </h2>
              <p className="text-white text-sm">{slide.text}</p>

              <span className="flex items-center gap-x-2 mt-small">
                <ArrowCircleRight size={26} weight="fill" color="#7AC143" />

                <Link className="text-sm text-white" to={slide.slug}>
                  Read More
                </Link>
              </span>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default LatestNewsSlider;
