import React from "react";
import Container from "components/Container/Container";
// import htmlToText from "he";

const DisplayData = ({ data, isSolution, slug }) => {
  console.log(data);
  return (
    <section className="pt-secondary pb-secondary lg:pb-primary">
      <Container>
        <div>
          <h2 className="text-3xl lg:text-5xl text-white font-[700] capitalize">
            {data?.title && (
              <>
                {data.title.split(" ").slice(0, -1).join(" ")}{" "}
                <span className="bg-lightGreen">
                  {data.title.split(" ").slice(-1)}
                </span>
              </>
            )}
          </h2>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 items-stretch gap-x-4 gap-y-4 mt-10">
          {data?.info_data?.map(({ title, image, text }, indx) => {
            const isLastChild = indx === data.info_data.length - 1;
            const isOdd = data.info_data.length % 2 !== 0;

            return (
              <div
                key={indx}
                className={`relative shadow p-7 min-h-[400px] h-full group overflow-hidden bg-cover bg-center flex flex-col ${
                  isOdd && isLastChild ? "lg:col-span-2" : ""
                }`}
                style={{ backgroundImage: `url(${image})` }}
              >
                <div className="absolute left-0 top-0 w-full h-full bg-[#05385599] transition ease-in duration-300 group-hover:bg-[#4a7a91] z-[0]"></div>
                <h2 className="text-white text-2xl font-[600] relative z-[10] ">
                  {title}
                </h2>

                <div className="flex-grow flex flex-col justify-center lg:min-h-0 relative z-[10] ">
                  {isSolution ? (
                    <div className="flex  transition ease-in duration-300 lg:translate-x-[40%] select-none lg:opacity-0 group-hover:opacity-100 items-center group-hover:select-auto group-hover:translate-x-[0%] text-white ">
                      <ul
                        className="list-disc w-full"
                        dangerouslySetInnerHTML={{
                          __html: text
                            .split("\r\n")
                            .filter((line) => line.trim() !== "")
                            .map((line) => `<li>${line}</li>`)
                            .join(""),
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className={`flex  h-full transition ease-in duration-300 lg:translate-x-[40%] select-none lg:opacity-0 group-hover:opacity-100  items-center group-hover:select-auto group-hover:translate-x-[0%] text-white py-3 ${
                        slug === "strategic-resourcing" && indx === 0
                          ? "flex-col gap-y-2 justify-center"
                          : ""
                      }`}
                    >
                      {slug === "strategic-resourcing" && indx === 0 && (
                        <p>
                          MDS Strategic Resourcing takes many shapes depending
                          on your requirement, from individual IT professionals
                          to entire teams, proving you with the ability to
                          rapidly scale the workforce, access specialized
                          skills, and maintain flexibility in staffing models.
                          Core business benefits include:
                        </p>
                      )}
                      <ul
                        className="list-disc w-full"
                        dangerouslySetInnerHTML={{
                          __html: text
                            .split("\r\n")
                            .filter((line) => line.trim() !== "")
                            .map((line) => `<li>${line}</li>`)
                            .join(""),
                        }}
                      />
                      {/* <p>{text}</p> */}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default DisplayData;
