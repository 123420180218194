import Container from "components/Container/Container";
import React from "react";
import image1 from "assests/image/Capabilties/1.png";
import image2 from "assests/image/Capabilties/2.png";
const SpecialData = () => {
  return (
    <section className="mt-secondary lg:mt-primary">
      <Container>
        {/* first */}
        <div className="text-white lg:text-center lg:w-3/4 lg:mx-auto">
          <h4 className="font-bold text-3xl lg:text-4xl">
            Customer Experience and Service Excellence:
          </h4>
          <p className="mt-3 mb-12">
            Central to our Service Framework is your success. We're dedicated to
            delivering exceptional customer experience and service excellence
            throughout your technology journey. With personalized support at
            every touchpoint, our team drives continuous improvement and
            innovation to propel your business forward. Trust MDS Computers for
            expert guidance and transformative service excellence.
          </p>

          <img className="w-3/4 mx-auto" src={image1} alt="" />
        </div>
      </Container>

      {/* second */}
      <div className="bg-lightBlue mt-secondary lg:mt-primary py-16 lg:text-center text-white">
        <Container>
          <div className="lg:w-3/4 lg:mx-auto">
            <h4 className="font-bold text-3xl lg:text-4xl">
              Your Technology Journey with Us
            </h4>
            <p className="mt-6 lg:mt-10">
              Embodying the continuous loop of the customer lifecycle journey,
              our logo's figure-eight design symbolizes our commitment to
              guiding you through every stage. With MDS Computers, expect
              seamless, efficient, and successful outcomes at every step of your
              journey. Experience unparalleled support and guidance as
              you embark with us.
            </p>
            <img className="my-6 lg:my-10" src={image2} alt="" />
            <p>
              Our Business Engagement pit stops (1-4) ensures that IT solutions
              fit your needs. We collaborate closely, understand your
              requirements, and identify opportunities for tech impact. Service
              Fulfillment pit stops (5-8) focus on delivering technical services
              for maximum ROI.
            </p>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default SpecialData;
