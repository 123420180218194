import React, { createContext, useState } from "react";
import GetContent from "utility/GetContent";
import { useLocation } from "react-router-dom";

export const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRequestError, setIsRequestError] = useState(false);
  const location = useLocation();

  const getBlogData = async (slug) => {
    setIsRequestError(false);
    try {
      setIsLoading(true);
      if (slug) {
        const blogData = await GetContent("blogs", slug);
        return blogData;
      } else if (!slug) {
        const blogData = await GetContent("blogs");
        return blogData;
      } else if (location.pathname.includes("blog")) {
        const blogData = await GetContent("blogs");
        return blogData;
      }
    } catch (error) {
      setIsRequestError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BlogContext.Provider
      value={{
        isLoading,
        isRequestError,
        getBlogData,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};
