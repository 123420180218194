import BASE_URL from "utility/BaseUrl";

const performGetFormStatus = async (page, slug, data) => {
  let url = `${BASE_URL}/${page}`;

  if (slug) {
    url += `/${slug}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(`Failed to get ${data}`);
      return response;
    } else {
      // console.log(`Get ${data} successful`);
      return responseData;
    }
  } catch (error) {
    // console.log(error);
    throw new Error(`Failed to perform Get ${data}`);
  }
};

export default performGetFormStatus;
