import footerBroder from "assests/image/Footer/footerBorder.png";

const FooterBorder = () => {
  return (
    <div>
      <img src={footerBroder} alt="" />
    </div>
  );
};

export default FooterBorder;
